import Pristine from 'pristinejs'
import { useHttp } from '@utils/useHttp'
import { Spinner } from '@components/Spinner'
import Layout from '@core/Layout'
import { useNotifications } from '@utils/useNotifications'
import { Sortable } from '@shopify/draggable'

export default class Labels extends Layout {
  constructor(options) {
    options = {
      apiEndPoints: {
        get: Routing.generate('app_ajax_labels_get', { ...(typeof taskId !== 'undefined' ? { taskId } : {}) }), //endpoint to get all labels useing teamId and taskId
        getAll: Routing.generate('app_ajax_labels_get_all'), //endpoint to get all labels
        post: Routing.generate('app_ajax_labels_create'), //endpoint to create new label
        edit: (id) => Routing.generate('app_ajax_labels_edit', { id }), //endpoint to edit label
        remove: Routing.generate('app_ajax_labels_remove'), //endpoint to remove label
        assign: Routing.generate('app_ajax_labels_assign'), //endpoint to assign the label to the task by taskId
        reorder: Routing.generate('app_ajax_labels_reorder'), //endpoint to reorder the labels list
      },
      manageMode: false,
      ...options,
    }
    super({
      selector: '.labels-wrapper',
    })

    this.pristine = null

    this.manageMode = options.manageMode
    this.apiEndPoints = options.apiEndPoints
    this.data = []
    this.isAdmin = []
    this.$labels = this.$target.find('.labels')

    this.$buttonWrapper = this.$target.find('.button-wrapper')
    this.$labelsDropdown = this.$buttonWrapper.find('.labels-dropdown')

    this.$labelsListWrapper = this.$labelsDropdown.find('.labels-list-wrapper')
    this.$labelsListSearch = this.$labelsListWrapper.find('.search-input')
    this.$labelsListUl = this.$labelsListWrapper.find('.labels-list-ul')
    this.$removeModal = this.$document.find('.remove-label-modal')

    if (this.manageMode) {
      this.$createLabelWrapper = this.$labelsDropdown.find('.create-label-wrapper')
      this.$form = this.$createLabelWrapper.find('form[name=new-label]')

      this.$labelsListUl = this.$labelsListWrapper.find('.labels-list-manage-ul')
    }

    /**
     * @todo create api endpoint to get all the settings to avoid global variables
     */
    if (enableTeamLabelsOption) {
      this.init()
    }
  }

  async init() {
    await this.finally(this.$buttonWrapper, true)
  }

  bindEvents() {
    $(window).on('mousedown touchstart', (e) => {
      if (!this.$buttonWrapper.is(e.target) && this.$buttonWrapper.has(e.target).length === 0) {
        this.$buttonWrapper.find('#toggle-labels-dropdown-checkbox').prop('checked', false)
      }
    })

    if (this.manageMode) {
      this.bindEventsManageMode()

      return
    }

    this.$labelsListSearch.find('input').on('keyup change', (e) => {
      const labels = this.$labelsListUl.find('li')

      this.toggleHidden(labels, true)
      Array.from(labels).forEach((li) =>
        $(li).toggleClass('hidden', !$(li).data('label-title').toLowerCase().includes(e.target.value.toLowerCase()))
      )
    })
  }

  bindEventsManageMode() {
    this.bindColorSet(this.$createLabelWrapper)

    this.$createLabelWrapper.find('.create-label-btn').on('click', async (e) => {
      e.preventDefault()
      const $this = $(e.target)
      const title = this.$createLabelWrapper.find('input').val()
      const color = this.$createLabelWrapper.find('.colors-set').find('.selected').data('color-name')

      if (this.pristine && this.pristine.validate()) {
        Spinner($this).prepend()
        this.toggleDisabled($this, true)

        try {
          await this.postLabel({
            title: title,
            color_class: color,
          })

          Spinner($this).remove()
          this.toggleDisabled($this, false)
          this.resetCreateForm()

          await this.finally(this.$labelsListUl, false)
        } catch (e) {
          console.log(e) //eslint-disable-line
        }
      }

      return false
    })

    const sortableLabels = new Sortable(this.$labelsListUl.find('ul')[0], {
      draggable: 'li',
      delay: 200,
    })

    sortableLabels
      .on('drag:start', ({ data }) => {})
      .on('drag:stop', async ({ data }) => {
        setTimeout(async () => {
          const labelsOrder = {}

          this.$labelsListUl
            .find('li')
            .toArray()
            .forEach((li) => {
              labelsOrder[$(li).index()] = $(li).data('label-id')
            })

          this.toggleLoader(this.$labelsListUl, true)

          const { request } = useHttp()

          try {
            await request(this.apiEndPoints.reorder, 'POST', labelsOrder)
            this.toggleLoader(this.$labelsListUl, false)
          } catch (err) {
            console.log(err) //eslint-disable-line
          }
        }, 0)
      })

    this.bindManageLabelsList()
    this.initializeValidation()
  }

  bindLabelsList() {
    this.$labelsListUl
      .find('li')
      .off('click')
      .on('click', async (e) => {
        const $currentTargetLi = $(e.currentTarget)
        const labelId = $currentTargetLi.data('label-id')

        $currentTargetLi.toggleClass('selected')

        try {
          this.toggleLoader(this.$buttonWrapper, true)
          await this.assignLabel(labelId)
          await this.fetchLabels()
          this.renderLabels()
          this.toggleLoader(this.$buttonWrapper, false)
        } catch (e) {
          console.log(e) //eslint-disable-line
        }
      })
  }

  bindManageLabelsList() {
    const allLabelsArray = Array.from(this.$labelsListUl.find('li'))

    allLabelsArray.forEach((li) => {
      const $li = $(li)
      const labelId = $li.data('label-id')
      const labelTitle = $li.data('label-title')

      this.bindColorSet($li)

      $li
        .find('> svg')
        .off('click')
        .on('click', () => {
          $li.toggleClass('edit-mode')
          $li.find('input').toggleClass('hidden')
        })

      $li
        .find('.save-btn')
        .off('click')
        .on('click', async () => {
          const labelTitleInput = $li.find('.label-title-input').val()
          const color = $li.find('.colors-set').find('.selected').data('color-name')

          await this.editLabel(labelId, labelTitleInput, color)
          await this.finally(this.$labelsListUl, false)
        })

      $li
        .find('.delete-btn')
        .off('click')
        .on('click', () => {
          this.removeLabel(labelId, labelTitle)
        })
    })
  }

  bindColorSet($parentEl) {
    const $colorsSet = $parentEl.find('.colors-set')

    $colorsSet
      .find('div[data-color-name]')
      .off('click')
      .on('click', (e) => {
        const $this = $(e.target)

        $colorsSet.find('div[data-color-name]').toggleClass('selected', false)
        $this.toggleClass('selected')
        $parentEl.find('.color-circle-placeholder')[0].classList = `color-circle-placeholder ${$this.data(
          'color-name'
        )}`
      })
  }

  initializeValidation() {
    this.pristine = new Pristine(this.$form.get(0))
  }

  resetCreateForm() {
    const $input = this.$createLabelWrapper.find('input')

    $input.val('')
  }

  async fetchLabels() {
    const { request } = useHttp()

    try {
      const response = await request(this.apiEndPoints[this.manageMode ? 'getAll' : 'get'], 'GET')
      this.data = response.data
      this.isAdmin = response.isAdmin
    } catch (e) {
      console.log(e) //eslint-disable-line
    }
  }

  async postLabel(query) {
    const { request } = useHttp()

    try {
      const res = await request(this.apiEndPoints.post, 'POST', query)
      useNotifications('success', res.message)
    } catch (e) {
      useNotifications('error', e.message)
      console.log(e) //eslint-disable-line
    }
  }

  removeLabel(labelId, labelTitle) {
    const { request } = useHttp()

    this.$buttonWrapper.find('#toggle-labels-dropdown-checkbox').prop('checked', false)
    this.$removeModal.modal('show')

    this.$removeModal.find('strong').text(labelTitle)

    this.$removeModal
      .find('.remove-label-btn')
      .off('click')
      .on('click', async (e) => {
        Spinner(e.target).prepend()
        this.toggleDisabled(e.target, true)

        try {
          await request(this.apiEndPoints.remove, 'POST', {
            labelId: labelId,
          })
          Spinner(e.target).remove()
          this.toggleDisabled(e.target, false)
          this.$removeModal.modal('hide')
          await this.finally(this.$labelsListUl, false)
        } catch (e) {
          console.log(e) //eslint-disable-line
        }
      })
  }

  async editLabel(labelId, title, color) {
    const { request } = useHttp()

    try {
      await request(this.apiEndPoints.edit(labelId), 'POST', {
        title,
        color_class: color,
      })
    } catch (e) {
      useNotifications('error', e.message)
      console.log(e) //eslint-disable-line
    }
  }

  async assignLabel(labelId) {
    const { request } = useHttp()

    try {
      await request(this.apiEndPoints.assign, 'POST', {
        taskId: taskId,
        labelId: labelId,
      })
    } catch (e) {
      console.log(e) //eslint-disable-line
    }
  }

  renderLabels() {
    const selectedLabels = this.data.filter((label) => {
      return +label.selected === 1
    })
    this.$labels.empty()
    selectedLabels.forEach((item) => this.$labels.append(this.getLabelHtml(item)))
  }

  renderListOfLabels() {
    const $ul = this.$labelsListUl.find('ul')
    $ul.empty()
    this.data.forEach((item) => this.$labelsListUl.find('ul').append(this.getLabelLiHtml(item)))
  }

  getLabelHtml(label) {
    return `
      <span class="label ${label.color_class}" data-label-id="${label.id}">${label.title}</span>
    `
  }

  getLabelLiHtml(label) {
    const editable = this.manageMode && this.isAdmin

    return `
      <li class="${editable ? '' : 'selectable'} ${+label.selected === 1 ? 'selected' : ''}" data-label-id="${
      label.id
    }" data-label-title="${label.title}">
        ${
          editable
            ? `
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.15659 2.68682H2.59035C2.16857 2.68682 1.76405 2.85438 1.4658 3.15263C1.16755 3.45088 1 3.85539 1 4.27718V15.4096C1 15.8314 1.16755 16.2359 1.4658 16.5342C1.76405 16.8324 2.16857 17 2.59035 17H13.7228C14.1446 17 14.5491 16.8324 14.8474 16.5342C15.1456 16.2359 15.3132 15.8314 15.3132 15.4096V9.84341M14.1204 1.49406C14.4368 1.17772 14.8658 1 15.3132 1C15.7605 1 16.1896 1.17772 16.5059 1.49406C16.8223 1.8104 17 2.23945 17 2.68682C17 3.1342 16.8223 3.56325 16.5059 3.87959L8.95176 11.4338L5.77106 12.2289L6.56624 9.04824L14.1204 1.49406Z" stroke="#296BB6" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>`
            : ''
        }
        <input type="text" class="basic-input label-title-input hidden" placeholder="${Translator.trans(
          'labels.type_label_name_here'
        )}" value="${label.title}" />
        <span class="label ${label.color_class}" data-label-id="${label.id}">${label.title}</span>
        ${
          editable
            ? `
          <div class="actions">
            <input id="color-set-trigger-checkbox-${label.id}" type="checkbox" hidden>
            <label for="color-set-trigger-checkbox-${label.id}" class="color-set-trigger">
                <span class="color-circle-placeholder ${label.color_class}"></span>
                <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L5.5 5.5L10 1" stroke="#919191" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </label>
            <div class="colors-set">
                <div class="red ${label.color_class === 'red' ? 'selected' : ''}" data-color-name="red"></div>
                <div class="yellow ${label.color_class === 'yellow' ? 'selected' : ''}" data-color-name="yellow"></div>
                <div class="green ${label.color_class === 'green' ? 'selected' : ''}" data-color-name="green"></div>
                <div class="bordo ${label.color_class === 'bordo' ? 'selected' : ''}" data-color-name="bordo"></div>
                <div class="blue ${label.color_class === 'blue' ? 'selected' : ''}" data-color-name="blue"></div>
                <div class="grey ${label.color_class === 'grey' ? 'selected' : ''}" data-color-name="grey"></div>
                <div class="lilac ${label.color_class === 'lilac' ? 'selected' : ''}" data-color-name="lilac"></div>
                <div class="dark-blue ${
                  label.color_class === 'dark-blue' ? 'selected' : ''
                }" data-color-name="dark-blue"></div>
                <div class="violet ${label.color_class === 'violet' ? 'selected' : ''}" data-color-name="violet"></div>
                <div class="lime-green ${
                  label.color_class === 'lime-green' ? 'selected' : ''
                }" data-color-name="lime-green"></div>
                <div class="cyan ${label.color_class === 'cyan' ? 'selected' : ''}" data-color-name="cyan"></div>
                <div class="desaturated-blue ${
                  label.color_class === 'desaturated-blue' ? 'selected' : ''
                }" data-color-name="desaturated-blue"></div>
                <div class="black ${label.color_class === 'black' ? 'selected' : ''}" data-color-name="black"></div>
                <div class="light-yellow ${
                  label.color_class === 'light-yellow' ? 'selected' : ''
                }" data-color-name="light-yellow"></div>
            </div>
            <button class="base-button save-btn">${Translator.trans('buttons.save')}</button>
            <button class="base-button delete-btn">${Translator.trans('buttons.delete')}</button>
          </div>
          `
            : ''
        }
      </li>
    `
  }

  async finally($loaderElement = null, bindEvents = false) {
    try {
      $loaderElement && this.toggleLoader($loaderElement, true)

      await this.fetchLabels()

      if (!this.manageMode) {
        this.renderLabels()
      }

      this.renderListOfLabels()
      $loaderElement && this.toggleLoader($loaderElement, false)
      bindEvents && this.bindEvents()

      if (this.manageMode) {
        this.bindManageLabelsList()
      } else {
        this.bindLabelsList()
      }
    } catch (e) {
      console.log(e) //eslint-disable-line
    }
  }
}
